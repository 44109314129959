@import "node_modules/bulma/bulma";

#videos .cell a {
    .thumbnails {
        position: relative;
    }

    .thumbnail-animated,
    .thumbnail-static {
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity .15s ease;
        width: 100%;
    }

    .thumbnail-animated {
        opacity: 0;
    }

    &:hover .thumbnail-animated,
    &:focus .thumbnail-animated {
        opacity: 1;
    }

    &:hover .thumbnail-static,
    &:focus .thumbnail-static {
        opacity: 0;
    }
}